/*=========================================================================================
  File Name: moduleDepartmentActions.js
  Description: Department Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
    AddMedicalPackageCategory({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.post("api/MedicalPackageCategory/AddMedicalPackageCategory", item)
                .then((response) => {
                    commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    GetAllMedicalPackageCategorys({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get("api/MedicalPackageCategory/GetAllMedicalPackageCategories")
                .then((response) => {
                    commit('SET_MedicalPackageCategory', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    SearchMedicalPackageCategories({ commit },data) {
        return new Promise((resolve, reject) => {
            axios.post("api/MedicalPackageCategory/SearchMedicalPackageCategories",data)
                .then((response) => {
                    commit('SET_MedicalPackageCategory', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    

    UpdateMedicalPackageCategory({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.post("api/MedicalPackageCategory/UpdateMedicalPackageCategory", item)
                .then((response) => {
                    commit('UPDATE_MedicalPackageCategory', item)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    GetMedicalPackageCategory({ commit }, itemid) {
        return new Promise((resolve, reject) => {
            axios.post("api/MedicalPackageCategory/GetMedicalPackageCategory", itemid)
                .then((response) => {
                    commit('UPDATE_MedicalPackageCategory', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    DeleteMedicalPackageCategory({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.delete("api/MedicalPackageCategory/DeleteMedicalPackageCategory?packageId=" + item.ID)
                .then((response) => {
                    commit('REMOVE_ITEM', item.ID)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
}