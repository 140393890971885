<template>
  <section id="card-actions">
    <b-row>

      <b-col >
        <b-card-actions
          ref="cardAction"
          title="Search"
          @refresh="refreshStop('cardAction')"
        >
          <b-row col="12">
            <b-col  cols="4">
              <b-form-group>
                    <v-select
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      style="width: 80%; border: none !important"
                      label="Name"
                      v-model="search.MedicalPackageCategorID"
                      :options="medicalPackageCategories"
                      :placeholder="$t('PackageCategory')"
                      :reduce="(ID) => ID.ID"
                      @input="GetAlMedicalPackagesCountries"
                    />
                  </b-form-group>

            </b-col>
            <b-col cols="3">
              <b-form-group>
              <v-select
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      style="width: 80%"        
                      id="destinationMobile"
                      label="Name"
                      v-model="search.CountryID"
                      :options="countries"
                      :reduce="(ID) => ID.ID"
                      @input="updateData"
                      :placeholder="$t('Destination')"
                    />
                  </b-form-group>

            </b-col>
            <b-col cols="3">
              <b-form-group>
              <v-select
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      @input="GetAllCategory"
                      label="Name"
                      v-model="search.HospitalID"
                      :options="hospitals"
                      :reduce="(ID) => ID.ID"
                      :placeholder="$t('Hospital')"
                    />
                  </b-form-group>

            </b-col>
            <b-col col="3">
              <b-button 
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
              @click="startSearchPackages">
              {{ $t("Search") }}
            </b-button>
            </b-col>

          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>

    <div id="packages">
      <b-row col="12 " id="packages">
        <b-col col="12" md="2"
      v-for="item in medicalPackages"
      :key="item.id"
      @OpenPackage="OpenPackage"
      >
      <b-card
      id="packages"
      :item="item"
      ::key="item.id"
      style="max-width: 20rem;"
      class="mb-2"
      :img-src="baseURL + item.ImagePath"
      @click="OpenPackage(item)"
      >
     
          <span style="font-family: 'Times New Roman', Times, serif;"> {{item.Name}}</span>
    
      </b-card>

      </b-col>
      </b-row>

    <DetailPackage v-if="selectedPackage.ID" id="DetailPackage"  :PackageID="selectedPackage.ID"  />
    </div>
  </section>

</template>

<script>
import { domain } from "@/gloabelConstant.js";
import moduleHospital from "@/store/hospital/moduleHospital.js";
import moduleMedicalPackage from "@/store/medicalPackage/moduleMedicalPackage.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import moduleMedicalPackageCategory from "@/store/settings/MedicalPackageCategory/moduleMedicalPackageCategory.js";
import DetailPackage from "@/views/package/DetailPackage.vue";
import PackageDetail from "@/views/package/PackageDetail.vue";
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import { BButton, BCard, BCardText, BCol, BFormGroup, BImg, BRow, BTable,BCardTitle } from 'bootstrap-vue';
import imageLazy from "@/components/image_lazy.vue";

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BTable,
    BCardText,
    BButton,
    BCard,
    BFormGroup,
    PackageDetail,
    DetailPackage,
    BImg,
    BCardTitle,
    imageLazy,

  },
  data() {
    return {
      fields: [
        'ACTION',
        { key: 'ICON', label: 'ICON' },
        'DETAILS',
      ],
      selectedPackage:{},
      EnglishLan: false,
        baseURL: domain,
      search: {
        MedicalPackageCategorID: "",
      }, 
      colorclass: {
      type: String,
      default: "whitecolor",
    }, 
    }
  },
  methods: {
    ViewPackageDetail(item) {
      debugger;
      if (!this.item.MedicalPackageCategoryID)
        this.$router.push("OurPackages/" + this.item.ID);
      else this.$emit("OpenPackage", this.item);
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName].showLoading = false
      }, 3000)
    },
    GetAllCategory(){
      this.$store
      .dispatch("MedicalPackageCategoryList/SearchMedicalPackageCategories" , this.search)
    },
    updateData(){
      this.$store
      .dispatch("MedicalPackageCategoryList/SearchMedicalPackageCategories" , this.search)
      this.$store.dispatch("HospitalList/SimpleSearchHospitals",this.search);
    },
   
    OpenPackage(item)
    {
      debugger;
        this.selectedPackage = item;
        this.$router.push({
        name: "DetailPackage",
        params: { ID: item.ID },
      });
      
    },
    startSearchPackages() {
      this.selectedPackage = {}
      this.$store.dispatch(
        "MedicalPackageList/SearchMedicalPackage",
        this.search
      );
    },
    back() {
      this.$router.go(-1);
    },
    GetAlMedicalPackagesCountries(){
      this.$store.dispatch("CountryList/GetAlMedicalPackagesCountries",this.search);
    }
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    hospitals() {
      debugger
      return this.$store.state.HospitalList.searchSimpleHospitals;
    },
    medicalPackageCategories() {
      debugger
      return this.$store.state.MedicalPackageCategoryList.MedicalPackageCategorys;
    },
    medicalPackages() {
      debugger;
      return this.$store.state.MedicalPackageList.medicalPackages;
    },
    countries() {
      return this.$store.state.CountryList.countries;
    },
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
      localStorage.getItem("SaveLang") == null
        ? true
        : false;
    if (!moduleMedicalPackageCategory.isRegistered) {
      debugger;
      this.$store.registerModule(
        "MedicalPackageCategoryList",
        moduleMedicalPackageCategory
      );
      moduleMedicalPackageCategory.isRegistered = true;
    }
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }
    if (!moduleHospital.isRegistered) {
      debugger;
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
    if (!moduleMedicalPackage.isRegistered) {
      this.$store.registerModule("MedicalPackageList", moduleMedicalPackage);
      moduleMedicalPackage.isRegistered = true;
    }
    this.selectedPackage = {}
this.updateData();
    debugger;
    if (this.$route.params.ID) {
      this.search.MedicalPackageCategorID = parseInt(this.$route.params.ID);
    }
    this.search.ValidDate = new Date();
    this.search.IsPublished = true;
    this.$store.dispatch(
      "MedicalPackageList/SearchMedicalPackage",
      this.search
    );
    this.GetAlMedicalPackagesCountries()
  },
  mounted(){
    
  },
}
</script>
